var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, [_c('Header', {
    attrs: {
      "id": "listing-header",
      "open-house": _vm.openHouse,
      "project": _vm.project,
      "project-statuscode": _vm.projectStatusCode
    }
  }), _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }