<template>
  <div style="overflow-x: scroll;">
    <Header
        id="listing-header"
        :open-house="openHouse"
        :project="project"
        :project-statuscode="projectStatusCode"
    />
    <router-view />
  </div>
</template>

<script>
import Header from "../../components/Deals/Detail/Header.vue";
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "DealsDetail",
  components: {
    Header,
  },
  data() {
    return {
      openHouse: null,
      projectStatusCode: null
    };
  },
  watch: {
    "$route.params.id": async function () {
      let chosenObjectId = this.$route.params.id;
      await Promise.all([
        this.getFirstOpenHouse(chosenObjectId),
      ])
    }
  },
  async created() {
    let chosenObjectId = this.$route.params.id;
    console.log(this.$route)
    await Promise.all([
      this.getFirstOpenHouse(chosenObjectId),
    ])
  },
  mounted() {},
  computed: {
    ...mapState({project: state => state.a.project})
  },
  methods: {
    async getFirstOpenHouse(chosenObjectId) {
      const response = await axios.get(`/api/task/project/open-house/${chosenObjectId}`);
      this.openHouse = response.data;
    },
    
  },
};
</script>
<style lang="scss" scoped>
.slide-animation-open {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}
</style>
