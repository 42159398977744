var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white",
      "padding-bottom": "0px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "title-row",
    staticStyle: {
      "padding-top": "8px",
      "min-height": "80px",
      "height": "unset"
    }
  }, [_c('v-col', {
    staticStyle: {
      "gap": "4px",
      "display": "grid"
    }
  }, [_vm.project.name ? _c('h4', {
    key: _vm.nameUpdater,
    staticClass: "header-title",
    attrs: {
      "id": "project-title"
    }
  }, [_vm._v(" " + _vm._s(_vm.project.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "broker-info"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/users.svg"),
      "alt": ""
    }
  }), _vm._l(_vm.project.access.users, function (creator, userKey) {
    return _c('span', {
      key: userKey,
      staticClass: "button-small hoverable-info",
      staticStyle: {
        "margin-left": "-2px"
      },
      on: {
        "click": function (event) {
          return _vm.openBrokerDetailPanel(event, userKey);
        }
      }
    }, [_vm._v(" " + _vm._s(creator.name) + " " + _vm._s(creator.ownershipType === "owner" ? "(" + _vm.$t("Owner").toLowerCase() + ")" : "") + " ")]);
  }), _vm.hasAccess(_vm.project) ? _c('button', {
    staticClass: "animated-button",
    on: {
      "click": function ($event) {
        return _vm.openAddUsers();
      }
    }
  }, [_c('p', {
    staticStyle: {
      "font-size": "16px",
      "margin-top": "-1px"
    }
  }, [_vm._v("+")]), _c('span', [_vm._v(_vm._s(_vm.$t("ManageUsers")))])]) : _vm._e()], 2), _vm.projectStatuscode === 0 ? _c('span', {
    staticStyle: {
      "background": "#fff0f0",
      "border-radius": "4px",
      "display": "flex",
      "justify-content": "center",
      "max-width": "296px"
    }
  }, [_c('p', {
    staticClass: "content-bold",
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("DeletedProject")) + " ")])]) : _vm._e(), _vm.openHouse ? _c('span', [_c('OpenHouseContainer', {
    attrs: {
      "task": _vm.openHouse
    }
  })], 1) : _vm._e()])], 1), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e(), _vm.isAddBrokerOpen ? _c('AddBrokerModal', {
    attrs: {
      "item": _vm.project,
      "project-modal": true
    },
    on: {
      "update:item": function ($event) {
        _vm.project = $event;
      },
      "close-modal": function ($event) {
        _vm.isAddBrokerOpen = false;
      },
      "updated": function ($event) {
        return _vm.getProject(_vm.project._id);
      },
      "addSharedOwner": _vm.handleAddSharedOwner,
      "removeSharedOwner": _vm.handleSharedBrokerRemoved,
      "transferProjectOwnership": _vm.handleTransferListing
    }
  }) : _vm._e(), _vm.transferConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.transferConfirmationOpen = false;
        _vm.isAddBrokerOpen = true;
      },
      "onConfirm": function ($event) {
        return _vm.transferProject();
      }
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("TransferingOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.transferedSharedBroker) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfProjectListings'),
      "type": "number"
    },
    model: {
      value: _vm.transferListings,
      callback: function ($$v) {
        _vm.transferListings = $$v;
      },
      expression: "transferListings"
    }
  })], 1)])])]) : _vm._e(), _vm.addSharedOwnerConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.addSharedOwnerConfirmationOpen = false;
      },
      "onConfirm": _vm.confirmAddSharedOwner
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "add-shared-owner"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSharedOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.newSharedBroker.name) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfProjectListings'),
      "type": "number"
    },
    model: {
      value: _vm.transferListings,
      callback: function ($$v) {
        _vm.transferListings = $$v;
      },
      expression: "transferListings"
    }
  })], 1)])])]) : _vm._e(), _vm.removeSharedOwnerConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.removeSharedOwnerConfirmationOpen = false;
      },
      "onConfirm": _vm.confirmRemoveSharedOwner
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "add-shared-owner"
  }, [_vm._v(" " + _vm._s(_vm.$t("RemoveSharedOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.removedSharedBroker) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfProjectListings'),
      "type": "number"
    },
    model: {
      value: _vm.transferListings,
      callback: function ($$v) {
        _vm.transferListings = $$v;
      },
      expression: "transferListings"
    }
  })], 1)])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }